import React, { useMemo } from "react";
import Table from "components/TableContainer/Table";

const InfoTable = (props) => {
  const { col1, col2, title, MainData } = props;

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: true,
        Cell: (order) => {
          return (
            <>
              <small className="text-muted">{parseInt(order.row.id) + 1}</small>
            </>
          );
        },
      },
      {
        Header: col1,
        accessor: "name",
      },
      {
        Header: col2,
        accessor: "value",
      },
    ],
    [col1, col2],
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        MainData={MainData}
        name={title}
        noSearch
        noPaging
      />
    </React.Fragment>
  );
};

export default InfoTable;
