import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";

import Loader from "components/common/Loader";
import * as ApiManager from "helpers/ApiManager.tsx";
import { Link } from "react-router-dom";

function SearchPlayer() {
  const [data, setdata] = useState(null);
  const [APIWorking, setAPIWorking] = useState(false);

  const InputRef = useRef();
  const isActive = useRef(false);

  useEffect(() => {
    isActive.current = true;

    return () => {
      isActive.current = false;
    };
  }, []);

  const handleSearch = async () => {
    if (APIWorking) return;
    let text = InputRef.current.value;
    if (text === "") return setdata(null);
    text = text.toLowerCase();

    setAPIWorking(true);
    const res = await ApiManager.SearchPlayer(text);
    if (isActive.current && res) {
      setdata(res.data);
    }
    setAPIWorking(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader className="align-items-center">
                <h4 className="card-title flex-grow-1">Search Player</h4>
              </CardHeader>
              <CardBody className="border border-dashed border-end-0 border-start-0">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <Row className="align-items-center">
                    <Col sm={{ size: 6, offset: 2 }}>
                      <div className="search-box">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Enter player name here..."
                          ref={InputRef}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col sm={4} className="col-xxl-2">
                      <div>
                        <Button color="primary" disabled={APIWorking}>
                          Search
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </CardBody>
              <CardBody className="pt-0 text-center">
                {APIWorking ? (
                  <Row className="mt-5">
                    <Col md={{ size: 6, offset: 3 }}>
                      <Loader />
                    </Col>
                  </Row>
                ) : data === null ? (
                  <h4 className="mt-4 pt-1">Enter player name to search</h4>
                ) : data.length === 0 ? (
                  <h4 className="mt-4 pt-1">No players found</h4>
                ) : (
                  data.map((x, i) => (
                    <div key={i} className={i === 0 ? "pt-4" : "p-2"}>
                      <Link to={`/player/info/${x.Name}`}>{x.Name}</Link>
                      <hr />
                    </div>
                  ))
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SearchPlayer;
