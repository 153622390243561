import Dashboard from "views/Dashboard";
import TopList from "views/TopList";
import SearchPlayer from "views/SearchPlayer";
import PlayerInfo from "views/PlayerInfo";
import AuroraUsers from "views/AuroraUsers";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-solid fa-house",
    component: <Dashboard />,
    layout: "",
  },
  {
    path: "/toplist",
    name: "TopList",
    icon: "fa fa-solid fa-tower-broadcast",
    component: <TopList />,
    layout: "",
  },
  {
    path: "/player/search",
    name: "Search Player",
    icon: "fa fa-solid fa-magnifying-glass",
    component: <SearchPlayer />,
    layout: "",
  },
  {
    path: "/player/info/:Name",
    name: "Player Info",
    icon: "fa fa-solid fa-house",
    component: <PlayerInfo />,
    layout: "",
    noSidebar: true,
  },
  {
    path: "/aurora",
    name: "Aurora Users",
    icon: "fa fa-solid fa-skull-crossbones",
    component: <AuroraUsers />,
    layout: "",
  },
  // {
  //   path: "/draws-list",
  //   name: "Draws List",
  //   icon: "fa fa-solid fa-gear",
  //   component: <DrawsList />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/bet-list",
  //   name: "Bets",
  //   icon: "fa-solid fa-right-left",
  //   component: <BetsList />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/dealers",
  //   name: "Dealers List",
  //   icon: "fa fa-solid fa-users",
  //   component: <DealersList />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/dealer-info",
  //   name: "Dealer Info",
  //   icon: "fa-solid fa-money-bill-transfer",
  //   component: <DealerInfo />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
  // {
  //   path: "/draw-info",
  //   name: "Draw Info",
  //   icon: "fa-solid fa-money-bill-transfer",
  //   component: <DrawInfo />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
  // {
  //   path: "/create-draw",
  //   name: "Create Draw",
  //   icon: "fa-solid fa-money-bill-transfer",
  //   component: <CreateDraw />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
  // {
  //   path: "/create-dealer",
  //   name: "Create Dealer",
  //   icon: "fa-solid fa-money-bill-transfer",
  //   component: <CreateDealer />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
  // {
  //   path: "/messagesupport",
  //   name: "MessageSupport",
  //   icon: "fa fa-solid fa-envelope",
  //   component: <MessageSupport />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/adminlog",
  //   name: "Admin Log",
  //   icon: "fa fa-solid fa-list",
  //   component: <AdminLog />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "tim-icons icon-single-02",
  //   component: <UserProfile />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
  // {
  //   path: "/usdg-log",
  //   name: "User Profile",
  //   icon: "tim-icons icon-single-02",
  //   component: <USDGLog />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
  // {
  //   path: "/withdraw-info",
  //   name: "Withdrawl Info",
  //   icon: "tim-icons icon-single-02",
  //   component: <WithdrawInfo />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
  // {
  //   path: "/swap-info",
  //   name: "Swap Info",
  //   icon: "tim-icons icon-single-02",
  //   component: <SwapInfo />,
  //   layout: "/admin",
  //   noSidebar: true,
  // },
];

export default routes;
