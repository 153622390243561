import React, { useMemo } from "react";
import Table from "components/TableContainer/Table";
import { Link } from "react-router-dom";

const VehicleTable = (props) => {
  const { MainData } = props;

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ID",
      },
      {
        Header: "Model",
        accessor: "Model",
      },
      {
        Header: "Owner",
        Cell: (order) => (
          <Link
            to={{
              pathname: `/player/info/${order.row.original.Owner}`,
            }}
          >
            {order.row.original.Owner}
          </Link>
        ),
      },
      {
        Header: "Shared",
        Cell: (order) => (
          <Link
            to={{
              pathname: `/player/info/${order.row.original.Shared}`,
            }}
          >
            {order.row.original.Shared}
          </Link>
        ),
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        MainData={MainData}
        name={"Vehicles"}
        noSearch
        noPaging
      />
    </React.Fragment>
  );
};

export default VehicleTable;
