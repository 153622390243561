import React, { useMemo } from "react";
import Table from "components/TableContainer/Table";

const WarningsTable = (props) => {
  const { MainData } = props;

  const columns = useMemo(
    () => [
      {
        Header: "Admin",
        accessor: "Admin",
      },
      {
        Header: "Date",
        accessor: "Date",
      },
      {
        Header: "Reason",
        accessor: "Reason",
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        MainData={MainData}
        name={"Warnings"}
        noSearch
        noPaging
      />
    </React.Fragment>
  );
};

export default WarningsTable;
