import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

import Loader from "components/common/Loader";
import * as ApiManager from "helpers/ApiManager.tsx";
import ToplistTable from "./ToplistTable";

function Toplist() {
  const [data, setdata] = useState(null);

  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      const res = await ApiManager.TopList();
      if (isActive && res) {
        setdata(res.data);
      }
    };
    fetchData();
    return () => {
      isActive = false;
    };
  }, []);

  return (
    <>
      <div className="content">
        {data === null ? (
          <Row>
            {Array.from({ length: 6 }, (v, i) => (
              <Col xs="12" md="6" key={i}>
                <Loader />
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            <Col xs="12" md="6">
              <ToplistTable MainData={data.Kills} col="Kills" />
            </Col>
            <Col xs="12" md="6">
              <ToplistTable MainData={data.Deaths} col="Deaths" />
            </Col>
            <Col xs="12" md="6">
              <ToplistTable MainData={data.Spree} col="Spree" />
            </Col>
            <Col xs="12" md="6">
              <ToplistTable MainData={data.Ratio} col="Ratio" />
            </Col>
            <Col xs="12" md="6">
              <ToplistTable
                MainData={data.TimePlayed.map((x) => ({
                  ...x,
                  TimePlayed: parseFloat((x.TimePlayed / 3600000).toFixed(3)),
                }))}
                col="TimePlayed"
                name="Time Played (Hrs)"
              />
            </Col>
            <Col xs="12" md="6">
              <ToplistTable
                MainData={data.Clans}
                col1="Clan"
                noLink
                name="Clans"
                col="Kills"
                colvalue="Kills"
              />
            </Col>
            <Col xs="12" md="6">
              <ToplistTable
                MainData={data.Headshots}
                col="Headshots"
                colvalue="Head"
              />
            </Col>
            <Col xs="12" md="6">
              <ToplistTable
                MainData={data["Monthly Kills"]}
                name="Monthly Kills"
                col="Kills"
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

export default Toplist;
