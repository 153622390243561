import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";

import Loader from "components/common/Loader";
import * as ApiManager from "helpers/ApiManager.tsx";
import { useParams } from "react-router-dom";
import InfoTable from "./InfoTable";
import VehicleTable from "./VehicleTable";
import { FormatNumber } from "helpers/utils";
import WarningsTable from "./WarningsTable";
import BansTable from "./BansTable";

const icons = [
  "tim-icons icon-single-02",
  "fa-solid fa-gun",
  "fa-solid fa-skull",
  "fa-solid fa-scale-balanced",
  "fa-solid fa-radiation",
  "fa-solid fa-clock",
];

function PlayerInfo() {
  const { Name } = useParams();

  const [data, setdata] = useState(null);

  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      const res = await ApiManager.PlayerInfo(Name);
      if (isActive && res) {
        setdata(res.data || "n/a");
      }
    };
    fetchData();
    return () => {
      isActive = false;
    };
  }, [Name]);

  return (
    <>
      <div className="content">
        {data === null ? (
          <Row>
            <Col>
              <Loader />
            </Col>
          </Row>
        ) : data === "n/a" ? (
          <Row>
            <Col>
              <Card>
                <CardHeader className="align-items-center">
                  <h4 className="card-title flex-grow-1">Player Information</h4>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <h4 className="text-danger">
                    {Name} is not registered in the server
                  </h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              {Object.entries(data.info).map((x, i) => (
                <Col key={i} sm={12} md={6} xl={4}>
                  <Card className="card-chart">
                    <CardHeader>
                      <h5 className="card-category">{x[0]}</h5>
                      <CardTitle tag="h3">
                        <i className={`${icons[i]} text-info`} />{" "}
                        {x[0] === "Time Played"
                          ? `${parseFloat(x[1].toFixed(2))} hour(s)`
                          : FormatNumber(x[1])}
                      </CardTitle>
                    </CardHeader>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col md={12} lg={6}>
                <InfoTable
                  MainData={data.body.map((x) => ({
                    name: x.name,
                    value: FormatNumber(parseInt(x.value)),
                  }))}
                  col1="Name"
                  col2="Kills"
                  title="Body Stats"
                />
              </Col>
              <Col md={12} lg={6}>
                <InfoTable
                  MainData={data.dailystats.map((x) => ({
                    name: x.name,
                    value: FormatNumber(parseFloat(x.value)),
                  }))}
                  col1="Name"
                  col2="Value"
                  title="Session Stats"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <VehicleTable MainData={data.vehicles} />
              </Col>
            </Row>
            <Row>
              <Col>
                <WarningsTable MainData={data.warnings} />
              </Col>
            </Row>
            <Row>
              <Col>
                <BansTable MainData={data.bans} />
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={6}>
                <InfoTable
                  MainData={data.weapons.map((x) => ({
                    name: x.name,
                    value: FormatNumber(parseFloat(x.value)),
                  }))}
                  col1="Name"
                  col2="Kills"
                  title="Weapon Stats"
                />
              </Col>
              <Col md={12} lg={6}>
                <InfoTable
                  MainData={data.skins}
                  col1="Skin ID"
                  col2="Price"
                  title="Owned Skins"
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default PlayerInfo;
