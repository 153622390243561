import React, { useMemo } from "react";
import Table from "components/TableContainer/Table";

const BansTable = (props) => {
  const { MainData } = props;

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (order) => {
          return (
            <>
              <small className="text-muted">{parseInt(order.row.id) + 1}</small>
            </>
          );
        },
      },
      {
        Header: "Ban Type",
        accessor: "BanType",
      },
      {
        Header: "Status",
        Cell: (order) => {
          let e = order.row.original.Status;
          return <span className={e === "Active" ? "text-info" : ""}>{e}</span>;
        },
      },
      {
        Header: "Admin",
        accessor: "Admin",
      },
      {
        Header: "Date",
        accessor: "Date",
      },
      {
        Header: "Duration",
        accessor: "time",
      },
      {
        Header: "Reason",
        accessor: "Reason",
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        MainData={MainData}
        name={"Bans History"}
        noSearch
        noPaging
      />
    </React.Fragment>
  );
};

export default BansTable;
