import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

import Loader from "components/common/Loader";
import * as ApiManager from "helpers/ApiManager.tsx";
import { Link } from "react-router-dom";

function Dashboard() {
  const [dashboardData, setdashboardData] = useState(null);

  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      const res = await ApiManager.ServerInfo();
      if (isActive && res) {
        setdashboardData(res.data);
      }
    };
    fetchData();
    return () => {
      isActive = false;
    };
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12" md="6">
            {dashboardData === null ? (
              <Loader />
            ) : (
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Server Name</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-single-02 text-info" />{" "}
                    {dashboardData.name}
                  </CardTitle>
                </CardHeader>
              </Card>
            )}
          </Col>
          <Col xs="12" md="3">
            {dashboardData === null ? (
              <Loader />
            ) : (
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Gamemode</h5>
                  <CardTitle tag="h3">
                    <i className="fa fa-ticket text-info" />{" "}
                    {dashboardData.gamemode}
                  </CardTitle>
                </CardHeader>
              </Card>
            )}
          </Col>
          <Col xs="12" md="3">
            {dashboardData === null ? (
              <Loader />
            ) : (
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Version</h5>
                  <CardTitle tag="h3">
                    <i className="tim-icons icon-wifi text-info" />
                    {dashboardData.version}
                  </CardTitle>
                </CardHeader>
              </Card>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {dashboardData === null ? (
              <Loader />
            ) : (
              <Card>
                <CardHeader className="align-items-center">
                  <h4 className="flex-grow-1 fw-bold text-center">
                    Online Players ({dashboardData.numplayers})
                  </h4>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0 text-center">
                  {dashboardData.players.map((x, i) => (
                    <div key={i}>
                      <Link to={`/player/info/${x}`}>{x}</Link>
                      <hr />
                    </div>
                  ))}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
