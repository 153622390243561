import React, { useMemo } from "react";
import Table from "components/TableContainer/Table";
import moment from "moment";
import { Link } from "react-router-dom";

const AuroraTable = (props) => {
  const { MainData } = props;

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        Cell: (order) => (
          <Link
            to={{
              pathname: `/player/info/${order.row.original.Name}`,
            }}
          >
            {order.row.original.Name}
          </Link>
        ),
      },
      {
        Header: "Time of Use",
        Cell: (order) => {
          return (
            <>{moment(order.row.original.TimeOfUse).format("DD MMMM YYYY")}</>
          );
        },
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <Table
        key={`Aurora-${MainData.length}`}
        columns={columns}
        MainData={MainData}
        noSearch
        noPaging
      />
    </React.Fragment>
  );
};

export default AuroraTable;
