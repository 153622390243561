import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Card, CardBody, CardHeader } from "reactstrap";

import Loader from "components/common/Loader";
import * as ApiManager from "helpers/ApiManager.tsx";
import AuroraTable from "./AuroraTable";

function AuroraList() {
  const [data, setdata] = useState(null);
  const [pageno, setpageno] = useState(0);
  const [showLoad, setshowLoad] = useState(true);
  const [APIWorking, setAPIWorking] = useState(false);
  const [SearchData, setSearchData] = useState(null);
  const [MainAPIWorking, setMainAPIWorking] = useState(false);

  useEffect(() => {
    let isActive = true;
    const fetchData = async () => {
      setAPIWorking(true);
      const res = await ApiManager.AuroraList(pageno);
      if (isActive && res) {
        if (data === null) setdata(res.data);
        else setdata((prevData) => [...prevData, ...res.data]);

        if (res.data.length === 0) setshowLoad(false);
      }
      setAPIWorking(false);
    };
    fetchData();
    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageno]);

  const InputRef = useRef();

  const handleSearch = async () => {
    if (MainAPIWorking || APIWorking) return;
    let text = InputRef.current.value;
    if (text === "") {
      setSearchData(null);
      return;
    }
    text = text.toLowerCase();
    setMainAPIWorking(true);
    const res = await ApiManager.SearchAurora(text);
    if (res) {
      setSearchData(res.data);
    }
    setMainAPIWorking(false);

    //    setFilteredData(GetFilteredData(activeTab).filter((x) => search(x, text)));
  };

  return (
    <>
      <div className="content">
        {data === null ? (
          <Row>
            <Col>
              <Loader />
            </Col>
          </Row>
        ) : (
          <>
            <Row className="">
              <Card>
                <CardHeader className="align-items-center">
                  <h4 className="card-title flex-grow-1">Aurora Users</h4>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}
                  >
                    <Row className="g-3 align-items-center">
                      <Col sm={{ size: 6, offset: 1 }} className="col-xxl-5">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder={"Search player..."}
                            ref={InputRef}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col sm={4} md={3} className="col-xxl-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-info w-100"
                            disabled={MainAPIWorking || APIWorking}
                            onClick={handleSearch}
                          >
                            {" "}
                            <i className="ri-equalizer-fill me-1 align-bottom"></i>
                            Search
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Col>
                {MainAPIWorking ? (
                  <Loader />
                ) : (
                  data && <AuroraTable MainData={SearchData || data} />
                )}
              </Col>
            </Row>
            {showLoad && SearchData === null && !MainAPIWorking && (
              <Row>
                <Col className="text-center">
                  <Button
                    color="info"
                    onClick={() => {
                      if (!APIWorking) setpageno(pageno + 1);
                    }}
                    disabled={APIWorking}
                  >
                    {APIWorking ? "Loading..." : "Load More"}
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default AuroraList;
