import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

const decryptData = (data: string): string => {
  const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_PVTKEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

// const paramsMaker = (data: object): string => {
//   return Object.keys(data).reduce((p, n) => {
//     if (data[n] !== undefined) {
//       if (p === "") p += "?";
//       else p += "&";
//       p += `${n}=${data[n]}`;
//     }
//     return p;
//   }, "");
// };

const CreateFetch = async (
  method: string,
  link: string,
  body?: object | null,
  istoken = true,
  noError = false,
) => {
  try {
    const token = istoken && sessionStorage.getItem("@token");
    const requestOptions: any = {
      method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : null,
      },
      body: body ? JSON.stringify(body) : null,
    };
    const res = await fetch(
      process.env.REACT_APP_BACKEND + link,
      requestOptions,
    );
    const jsonres: any = decryptData((await res.json()).data);
    // const jsonres = await res.json();

    if (jsonres.status === "fail") {
      toast.error(`You have been logged out`);
      return;
    }

    if (jsonres.status !== "success" && !noError) {
      toast.error(`${jsonres.message}`);
      return null;
    }
    return jsonres;
  } catch (e: any) {
    if (noError) return null;
    toast.error(`${e.message}`);

    return null;
  }
};
export async function ServerInfo() {
  return CreateFetch("GET", "server/info");
}
export async function TopList() {
  return CreateFetch("GET", "toplist");
}
export async function SearchPlayer(name: string) {
  return CreateFetch("GET", "player/search/" + name);
}
export async function PlayerInfo(name: string) {
  return CreateFetch("GET", "player/info/" + name);
}
export async function AuroraList(pageno: string) {
  return CreateFetch("GET", "player/aurora/list/" + pageno);
}
export async function SearchAurora(name: string) {
  return CreateFetch("GET", "player/aurora/search/" + name);
}
