import React, { useState, useRef } from "react";
import {
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import TableContainer from "components/TableContainer/TableContainer";
import classnames from "classnames";

const DealerTable = (props) => {
  const {
    MainData,
    name,
    search,
    columns,
    searchTxt,
    multi,
    initalTab = 0,
    noHeader,
    noSearch,
    pageSize = 10,
    noPaging,
  } = props;

  let Data = MainData;

  const tabs = multi ? multi(MainData) : [];

  const GetFilteredData = (id) => {
    if (multi) {
      let e = Object.keys(tabs)[id];
      return tabs[e];
    }
    return MainData;
  };
  const [activeTab, setActiveTab] = useState(initalTab);
  const [FilteredData, setFilteredData] = useState(GetFilteredData(initalTab));

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      const text = InputRef.current.value;
      if (text !== "") handleSearch(tab);
      else setFilteredData(GetFilteredData(tab));
    }
  };

  const InputRef = useRef();

  const handleSearch = () => {
    let text = InputRef.current.value;
    if (text === "") setFilteredData(Data);
    text = text.toLowerCase();
    setFilteredData(GetFilteredData(activeTab).filter((x) => search(x, text)));
  };

  return (
    <React.Fragment>
      <Card>
        {!noHeader && (
          <CardHeader className="align-items-center">
            <h4 className="card-title flex-grow-1">{name}</h4>
          </CardHeader>
        )}

        {!noSearch && (
          <CardBody className="border border-dashed border-end-0 border-start-0">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <Row className="g-3">
                <Col sm={{ size: 6, offset: 1 }} className="col-xxl-5">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control search"
                      placeholder={searchTxt}
                      ref={InputRef}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col sm={4} className="col-xxl-2">
                  <div>
                    <button
                      type="button"
                      className="btn btn-info w-100"
                      onClick={handleSearch}
                    >
                      {" "}
                      <i className="ri-equalizer-fill me-1 align-bottom"></i>
                      Search
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        )}
        <CardBody className="pt-0">
          <div>
            {multi && (
              <Nav className="nav-tabs mb-3">
                {Object.keys(tabs).map((x, i) => (
                  <NavItem key={i}>
                    <NavLink
                      className={classnames(
                        { active: activeTab === i },
                        "fw-semibold",
                      )}
                      onClick={() => {
                        toggleTab(i);
                      }}
                      href="#"
                    >
                      {x}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            )}
            <div className="mt-3">
              <TableContainer
                columns={columns}
                data={FilteredData}
                isGlobalFilter={false}
                isAddUserList={false}
                customPageSize={noPaging ? FilteredData.length : pageSize}
                noPaging={noPaging}
                divClass="table-responsive table-card mb-1"
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DealerTable;
