import React, { useMemo } from "react";
import Table from "components/TableContainer/Table";
import { Link } from "react-router-dom";
import { FormatNumber } from "helpers/utils";

const ToplistTable = (props) => {
  const { name, noLink, col1, col, colvalue } = props;
  let MainData = props.MainData;

  if (name !== "ime Played (Hrs)")
    MainData = MainData.map((x) => ({
      ...x,
      [colvalue || col]: FormatNumber(parseFloat(x[colvalue || col])),
    }));

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: true,
        Cell: (order) => {
          return (
            <>
              <small className="text-muted">{parseInt(order.row.id) + 1}</small>
            </>
          );
        },
      },
      {
        Header: col1 || "Name",
        Cell: (order) =>
          noLink ? (
            <>{order.row.original[col1 || "Name"]}</>
          ) : (
            <Link
              to={{
                pathname: `/player/info/${order.row.original[col1 || "Name"]}`,
              }}
            >
              {order.row.original[col1 || "Name"]}
            </Link>
          ),
      },
      {
        Header: col,
        accessor: colvalue || col,
      },
    ],
    [col, col1, noLink, colvalue],
  );

  return (
    <React.Fragment>
      <Table
        columns={columns}
        MainData={MainData}
        name={`Top ${name || col}`}
        noSearch
        noPaging
      />
    </React.Fragment>
  );
};

export default ToplistTable;
